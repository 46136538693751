<template>
	<a @click.prevent="openBookingEngine({ ...props })">
		<slot />
	</a>
</template>

<script setup>
import BookingEngine from '@becurious/mews';

const { locale } = useI18n();
const config = useRuntimeConfig();
const bookingEngine = useState('bookingEngine', () => {});

defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	promo: { type: String, default: '' },
	roomid: { type: String, default: '' },
});

useHead({
	script: [
		{
			key: 'mews-distributor',
			src: 'https://api.mews.com/distributor/distributor.min.js',
		},
	],
});

const openBookingEngine = (options) => {
	if (!bookingEngine.value) {
		return;
	}

	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	bookingEngine.value.visitUrl(options);
};

onMounted(() => {
	if (!bookingEngine.value) {
		bookingEngine.value = new BookingEngine({
			hotelID: '8cb7a814-ebb0-4195-9377-b04400c6f8c9',
			gtmID: config.public.googleTagManagerId,
			primaryColor: '',
		});
	}
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
